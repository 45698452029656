import { Autocomplete, Box, FormControl, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import { headerTable } from "../../../untils/static";

function MonitorCard({ data, dataHosp, dateStart, dateEnd, setDateStart, setDateEnd, searchLoca, setSearchLoca, setHcode }) {
    // console.log('dataHosp:', dataHosp)
    // console.log("data:", data);

    const [cleared, setCleared] = useState(false);
    useEffect(() => {
        if (cleared) {
            const timeout = setTimeout(() => {
                setCleared(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [cleared]);

    return (
        <Box display="flex" justifyContent="center">
            <Paper className="paper-bg" elevation={0}>
                <Grid container columns={12} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} className="flex-start">
                        <Stack direction="row" spacing={2}>
                            <Typography className="main-header">Monitor การนำเข้าข้อมูล</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Stack width="100%" direction="row" spacing={1}>
                            <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                <Typography mt={2} color="black">
                                    ตั้งแต่:
                                </Typography>
                            </Box>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateStart)}>
                                    <DatePicker
                                        // fullWidth
                                        value={dateStart}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่เริ่มต้น"
                                        format="DD/MM/YYYY"
                                        onChange={(e) => setDateStart(e)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <Box display={{ xs: "none", sm: "block" }} sx={{ width: 100 }}>
                                <Typography mt={2} color="black">
                                    จนถึง:
                                </Typography>
                            </Box>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs} defaultValue={dayjs(dateEnd)}>
                                    <DatePicker
                                        fullWidth
                                        value={dateEnd}
                                        slotProps={{
                                            field: { clearable: true, onClear: () => setCleared(true) },
                                        }}
                                        label="วันที่สิ้นสุด"
                                        format="DD/MM/YYYY"
                                        onChange={(e) => setDateEnd(e)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Autocomplete
                            fullWidth
                            getOptionLabel={(option) => (typeof option === "string" ? option : `${option.hname}`)}
                            options={dataHosp}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            value={searchLoca}
                            noOptionsText="No locations"
                            onChange={(event, newValue) => {
                                if (!newValue) {
                                    return;
                                }
                                if (newValue) {
                                    setSearchLoca(newValue.hname);
                                    setHcode(newValue.hcode);
                                    
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="รพ.สต." />}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <DataTable header={headerTable.MonitorInputData} data={data}/>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default MonitorCard;
